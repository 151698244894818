<template>
    <tr>
        <td>{{ id }}</td>
        <td>{{ number }}</td>
        <td>{{ agent }}</td>
        <td>{{ agent }}</td>
        <td>{{ comment }}</td>
        <td class="right"><span class="icon-more-vertical"></span></td>
    </tr>
</template>

<script>
    export default {
        props: ['id', 'number', 'comment', 'agent'],
    }
</script>