<template>
    <table class="col-12">
        <thead>
            <tr>
                <th class="width-10">Id</th>
                <th>Номер</th>
                <th>Контрагент</th>
                <th>Отчёты</th>
                <th>Примечание</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <GuidesElement 
                v-for="auto in autoList" 
                :key="auto.id"
                :id = "auto.id"
                :number="auto.number"
                :comment="auto.comment"
                :agent="auto.company"
            />
        </tbody>
    </table>
</template>
<script>
    import GuidesElement from "@/components/Guides/Autos/GuidesElement.vue";

    export default {
        components: { GuidesElement },
        computed: {
            autoList () {
                return this.$store.state.cars
            }
        },
        mounted() {
            this.$store.dispatch("fetchCars");
        }
    }
</script>